<!--包裹详情-->
<template>
  <div class="container">
    <div class="btnList1 wtl_btnList" ref="btnList">
      <div class="btnList1 wtl_btnList1">
        <el-button class="itemBtn btnColor" @click="kehugenzong"
          >客户追踪</el-button
        >
        <el-button
          v-if="hasPermiss('bggl_zongHeChaXun:express:query')"
          class="itemBtn btnColor"
          @click="queryTrack"
          >查询轨迹</el-button
        >
        <el-button class="itemBtn btnColor" @click="kehujiashou(1)"
          >客户加收</el-button
        >
        <el-button class="itemBtn btnColor" @click="kehujiashou(2)"
          >代理加收</el-button
        >
      </div>
      <div class="singleinpbtn wtl_queryBoxNew">
        <el-button class="seniorbut" @click="queryBtn_ok" icon="el-icon-search"
          >查询</el-button
        >
        <el-tooltip content="查询项恢复初始状态" placement="top">
          <div class="condition" @click="queryBtn_cz">清除</div>
        </el-tooltip>
        <el-tooltip content="查询更多条件" placement="top">
          <div class="condition" @click="more">
            {{ MoreConditions }}
          </div>
        </el-tooltip>
      </div>
    </div>
    <!-- 查询条件 -->
    <el-form :model="queryData">
      <div
        ref="queryCriteria"
        class="HeadInputbox"
        :class="{ HeadInputbox1: judge, active: isActive }"
      >
        <div class="item_right">
          <div class="singleinp">
            <div class="singleinpleft">快递单号:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model="queryData.courierNumber"
                clearable
                placeholder="请输入内容"
                @change="queryBtn_ok"
              ></el-input>
            </div>
          </div>

          <div class="singleinp" @click="changeVip">
            <div class="singleinpleft">会员号:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model="queryData.memberId"
                clearable
                placeholder="请输入内容"
              ></el-input>
            </div>
          </div>

          <!-- <div class="singleinp">
            <div class="singleinpleft">目的地:</div>
            <div class="singleinpright">
              <el-input class="input" placeholder="请输入内容"></el-input>
            </div>
          </div> -->

          <!-- <div class="singleinp">
            <div class="singleinpleft">渠道代理:</div>
            <div class="singleinpright">
              <el-select
                class="input"
                v-model="queryData.aaa"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in channelList"
                  :key="item.customerAgentId"
                  :label="item.agentName"
                  :value="item.customerAgentId"
                >
                </el-option>
              </el-select>
            </div>
          </div> -->

          <div class="singleinp">
            <div class="singleinpleft">仓库:</div>
            <div class="singleinpright">
              <el-select
                class="input"
                v-model="queryData.storageId"
                filterable
                clearable
                placeholder="请选择"
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in storageList"
                  :key="item.storageName"
                  :label="item.storageName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">包裹状态:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.packageStatus"
                filterable
                clearable
                placeholder="请选择"
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in packageStatusList"
                  :key="item.statusVal"
                  :label="item.showName"
                  :value="item.statusVal"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">验货状态:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.checkStatus"
                filterable
                clearable
                placeholder="请选择"
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in testStatusList"
                  :key="item.statusVal"
                  :label="item.showName"
                  :value="item.statusVal"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <!-- <div class="singleinp">
            <div class="singleinpleft">拒签状态:</div>
            <div class="singleinpright">
              <el-select
                class="input"
                v-model="queryData.isRejection"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in rejectionStatusList"
                  :key="item.statusVal"
                  :label="item.showName"
                  :value="item.statusVal"
                >
                </el-option>
              </el-select>
            </div>
          </div> -->
          <multitime
            :timeType="timeType"
            :timeTypes="timeTypes"
            :startTime="startTime_a"
            :endTime="endTime_a"
            @timestatus="timestatus"
            @queryBtn_ok="queryBtn_time"
          >
          </multitime>
          <!-- <div class="singleinp">
            <div class="singleinpleft">起始日期:</div>
            <div class="singleinpright">
              <el-date-picker
                class="input"
                v-model="queryData.startTime"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </div>
          </div>

          <div class="singleinp">
            <div class="singleinpleft">结束日期:</div>
            <div class="singleinpright">
              <el-date-picker
                class="input"
                v-model="queryData.endTime"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </div>
          </div> -->
        </div>
        <!-- <div class="item_left">
          <div class="singleinpbtn">
            <el-button
              class="seniorbut"
              @click="queryBtn_ok"
              icon="el-icon-search"
              >查询</el-button
            >
            <el-tooltip content="查询项恢复初始状态" placement="top">
              <div class="condition" @click="queryBtn_cz">清除</div>
            </el-tooltip>
            <el-tooltip content="查询更多条件" placement="top">
              <div class="condition" @click="more">
                {{ MoreConditions }}
              </div>
            </el-tooltip>
          </div>
        </div> -->
      </div>
    </el-form>
    <div class="contentInner">
      <!-- 左边快递列表 -->
      <div class="rightInner">
        <el-table
          :data="expressData"
          border
          ref="mytable"
          @row-dblclick="dblclick"
          highlight-current-row
          stripe
          size="small"
          :height="tableHeigth"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            align="center"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column
            align="center"
            :show-overflow-tooltip="true"
            label="快递单号"
            width="149"
          >
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="dark"
                content="双击查看详情"
                placement="top-start"
              >
                <div>{{ scope.row.courierNumber }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          layout="total,prev, next"
          prev-text="上一页"
          next-text="下一页"
          :current-page="pageStart"
          :total="pageCount"
          :page-size="pageTotal"
          @size-change="psc"
          @current-change="pcc"
        >
        </el-pagination>
      </div>
      <!-- 右边 -->
      <div class="leftInner">
        <el-tabs v-model="activeName" type="card" stretch>
          <el-tab-pane label="包裹信息" name="first">
            <el-descriptions title="基础信息" :column="5" border size="medium">
              <el-descriptions-item label="国内快递单号">{{
                packageDetail.courierNumber
              }}</el-descriptions-item>
              <el-descriptions-item label="包裹状态"
                >{{ packageDetail.packageStatusShow }}
              </el-descriptions-item>
              <el-descriptions-item label="仓库">{{
                packageDetail.storageName
              }}</el-descriptions-item>
              <el-descriptions-item label="货区">{{
                packageDetail.cargoareaName
              }}</el-descriptions-item>
              <el-descriptions-item label="货架号">{{
                packageDetail.frameName
              }}</el-descriptions-item>
              <el-descriptions-item label="会员昵称">{{
                packageDetail.memberNickname
              }}</el-descriptions-item>
              <el-descriptions-item label="拼团号"
                >{{ packageDetail.teamInvitationCode }}
              </el-descriptions-item>
              <el-descriptions-item label="团长会员号">{{
                packageDetail.teamMemberId
              }}</el-descriptions-item>
              <el-descriptions-item label="库存天数">{{
                packageDetail.packageStocDays
              }}</el-descriptions-item>
              <el-descriptions-item label="入库时间">{{
                packageDetail.inStockTime
              }}</el-descriptions-item>
              <el-descriptions-item label="物品类型">{{
                packageDetail.itemCategoryName
              }}</el-descriptions-item>
              <el-descriptions-item label="认领状态"
                >{{ packageDetail.packageIsClaim === 1 ? "已认领" : "未认领" }}
              </el-descriptions-item>
              <el-descriptions-item label="拒收状态"
                >{{ packageDetail.rejectionStatusShow }}
              </el-descriptions-item>
              <el-descriptions-item label="验货状态">{{
                packageDetail.checkStatusShow
              }}</el-descriptions-item>
              <el-descriptions-item label="异常状态"
                >{{ packageDetail.exceptionStatusShow }}
              </el-descriptions-item>
              <el-descriptions-item label="包裹件数">{{
                packageDetail.packageCount
              }}</el-descriptions-item>
              <el-descriptions-item label="包裹重量">{{
                packageDetail.packageWeight
              }}</el-descriptions-item>
              <el-descriptions-item label="包裹体积">{{
                packageDetail.packageVolume
              }}</el-descriptions-item>
              <el-descriptions-item label="添加包裹时间"
                >{{ packageDetail.packageCreateTime }}
              </el-descriptions-item>
              <el-descriptions-item label="添加包裹备注">{{
                packageDetail.packageComment
              }}</el-descriptions-item>

              <el-descriptions-item label="入库图片"
                ><div
                  v-if="
                    packageDetail.intoStockPics &&
                    packageDetail.intoStockPics.length > 0
                  "
                  class="lookBigImgs"
                  @click="loogImgs(packageDetail.intoStockPics, '入库图片')"
                >
                  点击查看
                </div></el-descriptions-item
              >
              <el-descriptions-item label="签收图片"
                ><div
                  v-if="
                    packageDetail.signForPics &&
                    packageDetail.signForPics.length > 0
                  "
                  class="lookBigImgs"
                  @click="loogImgs(packageDetail.signForPics, '签收图片')"
                >
                  点击查看
                </div></el-descriptions-item
              >
              <el-descriptions-item label="打包图片"
                ><div
                  v-if="
                    packageDetail.storePackPics &&
                    packageDetail.storePackPics.length > 0
                  "
                  class="lookBigImgs"
                  @click="loogImgs(packageDetail.storePackPics, '签收图片')"
                >
                  点击查看
                </div></el-descriptions-item
              >
              <el-descriptions-item label="包裹图片"
                ><div
                  v-if="
                    packageDetail.packagePics &&
                    packageDetail.packagePics.length > 0
                  "
                  class="lookBigImgs"
                  @click="loogImgs(packageDetail.packagePics, '签收图片')"
                >
                  点击查看
                </div></el-descriptions-item
              >
              <el-descriptions-item label="是否有投诉建议">
                <div v-if="packageDetail.isQuestion == 1">有</div>
                <div v-else>无</div>
              </el-descriptions-item>
              <!-- <el-descriptions-item label="采购单号">{{ packageDetail.packageComment}}</el-descriptions-item> -->
            </el-descriptions>
            <!-- 打包信息 -->
            <el-descriptions title="打包信息" :column="5" border size="medium">
              <el-descriptions-item label="打包批次号">{{
                packageDetail.billPackNumber
              }}</el-descriptions-item>
              <el-descriptions-item label="收件人">{{
                packageDetail.consigneeName
              }}</el-descriptions-item>
              <el-descriptions-item label="收件电话">{{
                packageDetail.receivingPhone
              }}</el-descriptions-item>
              <el-descriptions-item label="收件地址">{{
                packageDetail.addressee
              }}</el-descriptions-item>
              <el-descriptions-item label="邮政编号">{{
                packageDetail.postCode
              }}</el-descriptions-item>
              <el-descriptions-item label="申请打包时间"
                >{{ packageDetail.billPackCreateTime }}
              </el-descriptions-item>
              <el-descriptions-item label="渠道代理"
                >{{ packageDetail.customerAgentName }}
              </el-descriptions-item>
              <el-descriptions-item label="转单号">{{
                packageDetail.channelNumber
              }}</el-descriptions-item>
              <el-descriptions-item label="运输方式"
                >缺少字段</el-descriptions-item
              >
              <el-descriptions-item label="打包件数">{{
                packageDetail.packSumCount
              }}</el-descriptions-item>
              <el-descriptions-item label="打包重量">{{
                packageDetail.packSumWeight
              }}</el-descriptions-item>
              <el-descriptions-item label="打包体积">{{
                packageDetail.packVolume
              }}</el-descriptions-item>
              <el-descriptions-item label="渠道">{{
                packageDetail.channelName
              }}</el-descriptions-item>
              <el-descriptions-item label="出库时间">{{
                packageDetail.outStockTime
              }}</el-descriptions-item>
              <el-descriptions-item label="申请打包备注"
                >{{ packageDetail.billPackComment }}
              </el-descriptions-item>
              <el-descriptions-item label="打包备注"
                >{{ packageDetail.billStorepackComment }}
              </el-descriptions-item>
            </el-descriptions>
            <el-descriptions title="财务信息" :column="3" border size="medium">
              <el-descriptions-item label="应收金额"
                >{{ packageDetail.inSettlementAmount }}
              </el-descriptions-item>
              <el-descriptions-item label="会员付款"
                >{{ packageDetail.inWriteoffStatusShow }}
              </el-descriptions-item>
              <el-descriptions-item label="应付状态"
                >{{ packageDetail.outWriteoffStatusShow }}
              </el-descriptions-item>
            </el-descriptions>
          </el-tab-pane>
          <el-tab-pane label="操作日志" name="second">
            <el-table
              :data="oplogData"
              border
              :height="tableHeigth_log"
              stripe
              size="small"
              style="width: 100%"
            >
              <el-table-column type="index" label="序号" width="50">
              </el-table-column>
              <el-table-column
                prop="operatorUserName"
                align="center"
                label="操作人"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="operatorTime"
                align="center"
                :show-overflow-tooltip="true"
                label="操作时间"
                min-width="160"
              >
              </el-table-column>
              <el-table-column
                prop="operatorDevice"
                align="center"
                label="操作设备"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="operatorName"
                align="center"
                label="操作"
                :show-overflow-tooltip="true"
                min-width="160"
              >
              </el-table-column>
            </el-table>
            <paging
              ref="pags"
              :pageNum="pageStart_log"
              :total="pageCount_log"
              :sizeList="sizeList"
              :size="pageTotal_log"
              @handleSizeChange="handleSizeChange"
              @handleCurrentChange="handleCurrentChange"
            >
            </paging>
          </el-tab-pane>
          <el-tab-pane label="拒 收" name="third">
            <el-table
              :data="rejectionData"
              border
              stripe
              size="small"
              style="width: 100%"
            >
              <el-table-column
                type="index"
                align="center"
                label="序号"
                width="50"
              >
              </el-table-column>
              <el-table-column
                prop="operatorName"
                align="center"
                label="操作人"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="operationTime"
                align="center"
                :show-overflow-tooltip="true"
                label="操作时间"
                min-width="160"
              >
              </el-table-column>
              <el-table-column
                prop="deviceStatusShow"
                align="center"
                label="操作设备"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="courierNumber"
                align="center"
                label="来源单号"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="rejectionStatusShow"
                align="center"
                label="拒收退货类型"
                :show-overflow-tooltip="true"
                min-width="160"
              >
              </el-table-column>
              <el-table-column
                prop="comment"
                align="center"
                label="备注"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="name"
                align="center"
                label="附件"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="验 货" name="fourth">
            <el-table
              :data="InspectionData"
              border
              stripe
              size="small"
              style="width: 100%"
            >
              <el-table-column
                type="index"
                align="center"
                label="序号"
                width="50"
              >
              </el-table-column>
              <el-table-column
                prop="operatorName"
                align="center"
                label="操作人"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="operationTime"
                align="center"
                :show-overflow-tooltip="true"
                label="操作时间"
                min-width="160"
              >
              </el-table-column>
              <el-table-column
                prop="deviceStatus"
                align="center"
                label="操作设备"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="courierNumber"
                align="center"
                label="来源单号"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="inspectionComment"
                align="center"
                label="验货要求"
                :show-overflow-tooltip="true"
                min-width="160"
              >
              </el-table-column>
              <el-table-column
                prop="comment"
                align="center"
                label="验货申请备注"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="handlerName"
                align="center"
                label="处理人"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="handleTime"
                align="center"
                label="处理时间"
                :show-overflow-tooltip="true"
                min-width="160"
              >
              </el-table-column>
              <el-table-column
                prop="inspectionHandle"
                align="center"
                label="验货处理"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="inspectionResult"
                align="center"
                label="处理备注"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="name"
                align="center"
                label="附件"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="异 常" name="five">
            <el-table
              :data="abnormalData"
              border
              stripe
              size="small"
              style="width: 100%"
            >
              <el-table-column
                type="index"
                label="序号"
                align="center"
                width="50"
              >
              </el-table-column>
              <el-table-column
                prop="createName"
                align="center"
                label="操作人"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="operationTime"
                align="center"
                :show-overflow-tooltip="true"
                label="操作时间"
                min-width="160"
              >
              </el-table-column>
              <el-table-column
                prop="deviceStatusShow"
                align="center"
                label="操作设备"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="courierNumber"
                align="center"
                label="来源单号"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="exceptionTypeName"
                align="center"
                label="异常类型"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="comment"
                align="center"
                label="异常申请备注"
                :show-overflow-tooltip="true"
                min-width="160"
              >
              </el-table-column>
              <el-table-column
                prop="handlerName"
                align="center"
                label="处理人"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="handleTime"
                align="center"
                label="处理时间"
                :show-overflow-tooltip="true"
                min-width="160"
              >
              </el-table-column>
              <el-table-column
                prop="isHandleShow"
                align="center"
                label="处理状态"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="handleComment"
                align="center"
                label="处理备注"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="name"
                align="center"
                label="附件"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="应收明细" name="six" class="details">
            <el-table
              :data="moneyDetailData"
              border
              stripe
              size="small"
              style="width: 100%"
            >
              <el-table-column type="index" label="序号" width="50">
              </el-table-column>
              <el-table-column
                prop="operatorName"
                align="center"
                label="操作人"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="operationTime"
                align="center"
                :show-overflow-tooltip="true"
                label="操作时间"
                min-width="160"
              >
              </el-table-column>
              <el-table-column
                prop="deviceStatusShow"
                align="center"
                label="操作设备"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="documentStatusShow"
                align="center"
                label="收费项目"
                :show-overflow-tooltip="true"
                min-width="160"
              >
              </el-table-column>
              <el-table-column
                prop="settlementAmount"
                align="center"
                label="金额"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="包裹明细" name="seven">
            <el-table
              :data="packageDetailsData"
              border
              stripe
              size="small"
              style="width: 100%"
            >
              <el-table-column type="index" label="序号" width="50">
              </el-table-column>
              <el-table-column
                prop="itemName"
                align="center"
                label="包裹品名"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="unitPrice"
                align="center"
                :show-overflow-tooltip="true"
                label="包裹单价"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="quantity"
                align="center"
                label="包裹数量"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 会员管理查询弹出层 -->
    <el-dialog
      title="会员管理"
      :visible.sync="isDT"
      width="50%"
      :close-on-click-modal="false"
      @close="resetData('vf')"
    >
      <div class="newForm">
        <div class="formS">
          <el-form ref="vf" size="mini" :model="vf" label-width="80px">
            <el-row>
              <el-col :span="19" style="display: flex">
                <div class="queryItem1">
                  <el-form-item label="会员号:" prop="memberId">
                    <el-input v-model="vf.memberId"></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item label="会员名称:" prop="nickname">
                    <el-input v-model="vf.nickname"></el-input>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="1">
                <el-form-item label-width="10px">
                  <el-button type="warning" @click="cxEdit">查 询</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="tableBox">
          <el-table
            :data="ut"
            size="mini"
            max-height="400px"
            border
            style="width: 100%"
          >
            <el-table-column
              type="index"
              label="序号"
              align="center"
              width="50"
            >
            </el-table-column>
            <el-table-column
              prop="memberId"
              label="会员号"
              align="center"
              min-width="100"
            >
            </el-table-column>
            <el-table-column
              prop="nickName"
              label="会员名称"
              align="center"
              min-width="120"
            >
            </el-table-column>

            <el-table-column
              fixed="right"
              label="操作"
              align="center"
              min-width="100"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  style="color: #ef7c1b"
                  size="mini"
                  @click="xz(scope.row)"
                  >选 择
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页组件 -->
          <paging
            ref="pags"
            :pageNum="pageStart_u"
            :total="pageCount_u"
            :sizeList="sizeList"
            :size="pageTotal_u"
            @handleSizeChange="psc_u"
            @handleCurrentChange="pcc_u"
          ></paging>
        </div>
      </div>
    </el-dialog>
    <!-- 查询轨迹 -->
    <el-dialog
      title="查询轨迹"
      :visible.sync="isDT_query"
      width="56%"
      :close-on-click-modal="false"
      @close="resetData('eidtQuery')"
    >
      <div class="queryBox">
        <el-form
          ref="eidtQuery"
          size="mini"
          :inline="true"
          :model="eidtQuery"
          label-width="80px"
        >
          <el-form-item label="快递单号">
            <el-input v-model="eidtQuery.courierNumber" clearable></el-input>
          </el-form-item>

          <el-form-item label="快递公司编码">
            <el-select v-model="eidtQuery.expressCode" clearable filterable>
              <el-option
                v-for="item in kuaidiList"
                :key="item.expressNumber"
                :label="item.expressName"
                :value="item.expressNumber"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="eidtQuery.phone" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="eidtQuerySubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="showBox">
        <div class="countryGrounp">
          <div class="countryItem">
            {{ formQuery.areaCountryName }}
          </div>
          <div class="number">
            {{ formQuery.courierNumber }}
          </div>
        </div>
      </div>
      <div class="contentBox">
        <el-table
          :data="formQuery.data"
          size="mini"
          max-height="500"
          stripe
          highlight-current-row
          border
          style="width: 100%"
        >
          <el-table-column
            align="center"
            prop="time"
            label="时间"
            min-width="30%"
          >
          </el-table-column>
          <el-table-column
            prop="context"
            align="center"
            :show-overflow-tooltip="true"
            label="轨迹"
            min-width="70%"
          >
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- 客户加收弹窗 -->
    <el-dialog
      :title="titleH"
      :visible.sync="isDT_addC"
      width="50%"
      center
      :close-on-click-modal="false"
      @close="resetData('customerForm')"
    >
      <div class="newForm customerForm">
        <el-form
          ref="customerForm"
          :model="customerForm"
          :rules="customerRules"
          label-width="100px"
        >
          <!-- <div class="queryItem1">
            <el-form-item label="加收编号:">
              <el-input
                v-model="customerForm.courierNumber"
                readonly
                placeholder="添加后自动生成"
              ></el-input>
            </el-form-item>
          </div> -->
          <template v-if="isCustomer">
            <div class="queryItem1" @click="changeVip">
              <el-form-item label="会员号:" prop="userName">
                <el-input
                  v-model="customerForm.userName"
                  readonly
                  placeholder="请选择"
                ></el-input>
              </el-form-item>
            </div>
          </template>
          <template v-else>
            <div class="queryItem1">
              <el-form-item label="渠道代理:" prop="customerAgentId">
                <el-select
                  v-model="customerForm.customerAgentId"
                  clearable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in agentArr"
                    :key="item.customerAgentId"
                    :label="item.agentName"
                    :value="item.customerAgentId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </template>
          <div class="queryItem1">
            <el-form-item label="来源单据:" prop="financeDocumentstatusId">
              <el-select
                v-model="customerForm.financeDocumentstatusId"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in lyArr"
                  :key="item.financeDocumentstatusId"
                  :label="item.documentStatusName"
                  :value="item.financeDocumentstatusId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="来源单号:" prop="documentNumber">
              <el-input
                v-model="customerForm.documentNumber"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </div>
          <!--<div class="queryItem1">-->
          <!--<el-form-item label="会员名称:" prop="nickname">-->
          <!--<el-input v-model="customerForm.nickname"></el-input>-->
          <!--</el-form-item>-->
          <!--</div>-->
          <div class="queryItem1">
            <el-form-item label="加收项目:" prop="itemServiceId">
              <el-select
                v-model="customerForm.itemServiceId"
                @change="changeServe"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in serveArr"
                  :key="item.itemServiceId"
                  :label="item.title"
                  :value="item.itemServiceId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="金额:" prop="settlementAmount">
              <el-input v-model="customerForm.settlementAmount"></el-input>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="仓库:">
              <el-select
                class="input"
                v-model="customerForm.storageId"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in storageList"
                  :key="item.storageName"
                  :label="item.storageName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="queryItem1">
            <el-form-item label="加收备注:" prop="comment">
              <el-input v-model="customerForm.comment"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="warning" size="mini" @click="addCustomerM"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 查看图片 -->
    <el-dialog
      :title="imgTitle"
      :visible.sync="showImgs"
      width="50%"
      center
      :close-on-click-modal="false"
      @close="resetData('customerForm')"
    >
      <div class="newForm customerForm">
        <el-image
          class="lookImage"
          v-for="(item, index) in imgLists"
          :key="index"
          :src="item"
          :preview-src-list="imgLists"
        >
        </el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="warning" size="mini" @click="showImgs = false"
          >关闭</el-button
        >
      </span>
    </el-dialog>

    <addCustomerTarck
      :showDia1="showDia1"
      :documentNumbers="documentNumbers"
      :documentStatus="documentStatus"
      :isdisabledStatus="true"
      @confirmAdd="confirmAdd"
      @closeDia1="closeDia1"
    ></addCustomerTarck>
  </div>
</template>

<script>
import { Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import paging from "@/components/pagings.vue";
import addCustomerTarck from "@/components/CustomerServiceTrackingNew.vue";
import multitime from "@/components/multiTime.vue";
export default {
  components: { paging, addCustomerTarck, multitime },
  data() {
    return {
      isActive: true, //  输入框绑定动态class
      judge: false, //  输入框绑定动态class
      MoreConditions: "更多条件", //  输入框绑定动态class
      queryData: {
        courierNumber: "",
        memberId: "",
        storageId: "",
        packageStatus: "",
        checkStatus: "",
        isRejection: "",
        startTime: "",
        endTime: "",
      },
      channelList: [], //  渠道代理下拉列表
      storageList: [], //  仓库下拉列表
      kuaidiList: [], //快递公司下拉
      packageStatusList: [], //  包裹状态下拉列表
      testStatusList: [], //  验货状态下拉列表
      rejectionStatusList: [], //  拒签状态下拉列表
      expressData: [], //  快递单号表格
      tableHeigth: 0, //  表格高度
      tableHeigth_log: 0,
      pageStart: 1, //  分页配置
      pageTotal: 50,
      pageCount: 0,
      sizeList: [10, 20, 50, 100, 500],
      //    会员选择
      isDT: false,
      vf: {
        memberId: "",
        nickname: "",
      },
      ut: [],
      pageStart_u: 1, //  分页配置
      pageTotal_u: 50,
      pageCount_u: 0,
      //    查询轨迹
      isDT_query: false,
      eidtQuery: {
        courierNumber: "",
        expressCode: "",
        phone: "",
      }, //   查询表单
      formQuery: {},
      //    tab标签页
      activeName: "first",
      packageDetail: {
        courierNumber: "",
        packageStatusShow: "",
        storageName: "",
        cargoareaName: "",
        frameName: "",
        memberNickname: "",
        teamInvitationCode: "",
        teamMemberId: "",
        packageStocDays: "",
        inStockTime: "",
        itemCategoryName: "",
        packageIsClaim: "",
        rejectionStatusShow: "",
        checkStatusShow: "",
        exceptionStatusShow: "",
        packageCount: "",
        packageWeight: "",
        packageVolume: "",
        packageCreateTime: "",
        packageComment: "",
        billPackNumber: "",
        consigneeName: "",
        receivingPhone: "",
        addressee: "",
        postCode: "",
        billPackCreateTime: "",
        customerAgentName: "",
        channelNumber: "",
        packSumCount: "",
        packSumWeight: "",
        packVolume: "",
        channelName: "",
        outStockTime: "",
        billPackComment: "",
        billStorepackComment: "",
        inSettlementAmount: "",
        inWriteoffStatusShow: "",
        outWriteoffStatusShow: "",
      },
      oplogData: [], //  操作日志表格数据
      rejectionData: [], //  拒收表格数据
      InspectionData: [], //  验货表格数据
      abnormalData: [], //  异常表格数据
      moneyDetailData: [], //  应收明细表格数据
      packageDetailsData: [], //  包裹明细表格数据
      pageStart_log: 1,
      pageTotal_log: 50,
      pageCount_log: 0,
      packageId: undefined,
      //    客户加收
      isDT_addC: false,
      customerForm: {
        settlementAmount: "",
        financeDocumentstatusId: "",
        customerAgentId: "",
        itemServiceId: "",
        comment: "",
        documentNumber: "",
        storageId: "",
        serviceName: "",
      },
      customerRules: {
        settlementAmount: [{ required: true, message: " ", trigger: "blur" }],
        userName: [{ required: true, message: " ", trigger: "blur" }],
        financeDocumentstatusId: [
          { required: true, message: " ", trigger: "change" },
        ],
        customerAgentId: [{ required: true, message: " ", trigger: "change" }],
      },
      lyArr: [],
      serveArr: [],
      isCustomer: true,
      agentArr: [],
      titleH: "",
      uids: "",

      showDia1: false,
      documentNumbers: "",
      documentStatus: "", //
      // 时间组件
      timeTypes: [
        //  时间类型
        {
          value: "0",
          label: "添加时间",
        },
      ],
      timeType: "0", //  时间类型
      startTime_a: "",
      endTime_a: "",

      // 查看图片弹窗
      imgTitle: "",
      showImgs: false,
      imgLists: [], //预览的图片
    };
  },
  watch: {
    activeName: function (newVal, oldVal) {
      this.getpageDatas();
    },
  },
  created() {
    this.packageId = this.$route.query.packageId;
    if (this.packageId) {
      this.queryData.courierNumber = this.$route.query.courierNumber;
      // this.getPackageDetails(); //  获取包裹详情
      // this.getPackageLogOperates(); //  获取操作日志
      // this.getPackageItems(); //  获取包裹明细
      // this.myGetPackageRejectionDetail(); //  获取包裹详情-拒收
      // this.myGetPackageInspectionDetail(); //  获取包裹详情-验货
      // this.myGetPackageExceptionDetail(); //   获取包裹详情-异常
      // this.myGetPackageFinanceInDetail(); //   获取包裹详情-应收明细
      this.getpageDatas();
    }
    this.getExpressData(); //  获取快递单号表格
    this.getCangkuList(); //  获取仓库下拉列表
    this.expressChooseList(); //货区运快递公司
    // this.getChannelList(); //  获取渠道代理下拉列表
    this.myGetStatusValList("package_v1.package_status", 1); //  包裹下拉
    this.myGetStatusValList("bill_inspection_v1.check_status", 2); //  验货下拉
    this.myGetStatusValList("bill_rejection_v1.handle_status", 3); //  拒签
  },
  mounted() {},
  methods: {
    loogImgs(e, title) {
      let arrs = e || [];
      let imgs = [];
      for (var i = 0; i < arrs.length; i++) {
        imgs.push(arrs[i].fileUrl);
      }
      console.log(imgs);
      this.imgLists = imgs;
      this.showImgs = true;
      this.imgTitle = title;
    },
    // 时间组件方法
    queryBtn_time(s, e) {
      this.startTime_a = s || "";
      this.endTime_a = e || "";
    },
    timestatus(type, s, e) {
      this.timeType = type || "0";
      this.startTime_a = s || "";
      this.endTime_a = e || "";
    },
    // 提交新建
    confirmAdd(formName) {
      this.showDia1 = false;
      this.getData();
    },
    // 关闭重置弹窗数据
    closeDia1(e) {
      this.showDia1 = e || false;
      this.documentNumbers = "";
    },
    //货区运快递公司
    expressChooseList() {
      Api.expressChooseList().then((res) => {
        if (res.data.status === "success") {
          this.kuaidiList = res.data.result || [];
        }
      });
    },
    getCangkuList() {
      //  获取仓库下拉列表
      Api.getStorageList().then((res) => {
        if (res.data.status === "success") {
          this.storageList = res.data.result;
        } else {
          this.$message.error(`${res.data.message}--仓库下拉列表`);
        }
      });
    },
    getChannelList() {
      //  获取渠道代理下拉列表
      Api.channelAgentChooseList().then((res) => {
        if (res.data.status === "success") {
          this.channelList = res.data.result;
        } else {
          this.$message.error(`${res.data.message}--渠道代理下拉列表`);
        }
      });
    },
    myGetStatusValList(status, type) {
      //  获取公共下拉列表
      Api.getStatusValList({ tableAndFieldName: status }).then((res) => {
        if (res.data.status === "success") {
          switch (type) {
            case 1:
              this.packageStatusList = res.data.result; //  包裹
              break;
            case 2:
              this.testStatusList = res.data.result; //  验货
              break;
            case 3:
              this.rejectionStatusList = res.data.result; //  拒签
              break;
          }
        } else {
          this.$message.error(`${res.data.message}--公共状态`);
        }
      });
    },
    getExpressData() {
      //  获取快递单号表格
      let startTime = "";
      let endTime = "";
      if (this.startTime_a) {
        startTime = this.startTime_a + " 00:00:00";
      }
      if (this.endTime_a) {
        endTime = this.endTime_a + " 00:00:00";
      }
      this.queryData.pageStart = this.pageStart;
      this.queryData.pageTotal = this.pageTotal;
      this.queryData.addEndTime = endTime;
      this.queryData.addStartTime = startTime;
      let memberArr = tools.getOrderNum(this.uids) || [];
      let courierNumberArr =
        tools.getOrderNum(this.queryData.courierNumber) || [];
      this.queryData.userIds = memberArr;
      // this.queryData.courierNumber = courierNumberArr;
      let param = JSON.parse(JSON.stringify(this.queryData));
      param.courierNumber = courierNumberArr;
      Api.courierNumberList(param).then((res) => {
        if (res.data.status === "success") {
          this.expressData = res.data.result.data;
          this.pageCount = res.data.result.pageCount || 0;
          this.fetTableHeight();
        } else {
          this.$message.error(`${res.data.message}--快递单号列表API`);
        }
      });
    },
    getPackageDetails() {
      //  获取包裹详情
      Api.packageDetails({
        packageId: this.packageId,
      }).then((res) => {
        if (res.data.status === "success") {
          this.packageDetail = res.data.result;
        } else {
          this.$message.error(`${res.data.message}--包裹详情`);
        }
      });
    },
    getPackageLogOperates() {
      //  获取操作日志
      Api.packageLogOperates({
        packageId: this.packageId,
        pageStart: this.pageStart_log,
        pageTotal: this.pageTotal_log,
      }).then((res) => {
        if (res.data.status === "success") {
          console.log(res.data.result.data);
          this.oplogData = res.data.result.data || [];
          this.pageCount_log = res.data.result.pageCount;
        } else {
          this.$message.error(`${res.data.message}--操作日志`);
        }
      });
    },
    myGetPackageRejectionDetail() {
      //  获取包裹详情-拒收
      Api.getPackageRejectionDetail({
        packageId: this.packageId,
      }).then((res) => {
        if (res.data.status === "success") {
          this.rejectionData = res.data.result;
        } else {
          this.$message.error(`${res.data.message}--获取包裹详情-拒收`);
        }
      });
    },
    myGetPackageInspectionDetail() {
      //  获取包裹详情-验货
      Api.getPackageInspectionDetail({
        packageId: this.packageId,
      }).then((res) => {
        if (res.data.status === "success") {
          this.InspectionData = res.data.result;
        } else {
          this.$message.error(`${res.data.message}--获取包裹详情-验货`);
        }
      });
    },
    myGetPackageExceptionDetail() {
      //  获取包裹详情-异常

      Api.getPackageExceptionDetail({
        packageId: this.packageId,
      }).then((res) => {
        if (res.data.status === "success") {
          this.abnormalData = res.data.result;
        } else {
          this.$message.error(`${res.data.message}--获取包裹详情-异常`);
        }
      });
    },
    myGetPackageFinanceInDetail() {
      //  获取包裹详情-应收明细
      Api.getPackageFinanceInDetail({
        packageId: this.packageId,
      }).then((res) => {
        if (res.data.status === "success") {
          this.moneyDetailData = res.data.result;
        } else {
          this.$message.error(`${res.data.message}--获取包裹详情-应收明细`);
        }
      });
    },
    getPackageItems() {
      //  获取包裹明细
      Api.packageItems({
        packageId: this.packageId,
      }).then((res) => {
        if (res.data.status === "success") {
          this.packageDetailsData = res.data.result;
        } else {
          this.$message.error(`${res.data.message}--包裹明细`);
        }
      });
    },
    // getPics() {
    //     //  3-拒收、4-验货、5-异常
    //     switch (num) {
    //         case 3:
    //             Api.getPackageRejectionPic({
    //                 packageId: this.packageId
    //             }).then((res) => {
    //                 if (res.data.status === 'success') {
    //                     this.packageDetailsData = res.data.result
    //                 } else {
    //                     this.$message.error(`${res.data.message}--包裹明细`)
    //                 }
    //             });
    //             break
    //         case 4:
    //             Api.packageItems({
    //                 packageId: this.packageId
    //             }).then((res) => {
    //                 if (res.data.status === 'success') {
    //                     this.packageDetailsData = res.data.result
    //                 } else {
    //                     this.$message.error(`${res.data.message}--包裹明细`)
    //                 }
    //             });
    //             break
    //         case 5:
    //             Api.packageItems({
    //                 packageId: this.packageId
    //             }).then((res) => {
    //                 if (res.data.status === 'success') {
    //                     this.packageDetailsData = res.data.result
    //                 } else {
    //                     this.$message.error(`${res.data.message}--包裹明细`)
    //                 }
    //             });
    //             break
    //     }
    // },

    queryForKD100() {
      //  查询快递轨迹
      let param = {
        courierNumber: this.eidtQuery.courierNumber,
        expressCode: this.eidtQuery.expressCode,
        phone: this.eidtQuery.phone || "",
      };
      param.sign = tools.getSign(param);
      Api.expressQueryForKD100(param).then((res) => {
        if (res.data.status === "success") {
          this.formQuery = res.data.result;
        } else {
          this.$message.error(`${res.data.message}--查询快递轨迹`);
        }
      });
    },
    eidtQuerySubmit() {
      this.queryForKD100();
    },
    more() {
      this.isActive = !this.isActive;
      this.judge = !this.judge;
      this.fetTableHeight();
      if (this.judge) {
        this.MoreConditions = "收起条件";
      } else {
        this.MoreConditions = "更多条件";
      }
    },
    queryBtn_cz() {
      //  重置
      this.queryData = {};
      this.queryData.userIds = [];
    },
    // 查询按钮
    queryBtn_ok() {
      this.pageStart = 1;
      this.getExpressData();
    },
    psc(val) {
      //  表格分页-当前页面总条数
      this.pageTotal = val;
      this.getExpressData();
    },
    pcc(val) {
      //  表格分页-页码
      this.pageStart = val;
      this.getExpressData();
    },
    dblclick(row) {
      //console.log(row)
      this.packageId = row.packageId;
      // this.getPackageDetails(); //  获取包裹详情
      // this.getPackageLogOperates(); //  获取包裹操作日志
      // this.getPackageItems(); //  获取包裹明细
      // this.myGetPackageRejectionDetail(); //  获取包裹详情-拒收
      // this.myGetPackageInspectionDetail(); //  获取包裹详情-验货
      // this.myGetPackageExceptionDetail(); //   获取包裹详情-异常
      // this.myGetPackageFinanceInDetail(); //   获取包裹详情-应收明细
      this.getpageDatas();
    },

    getpageDatas() {
      let val = this.activeName;
      switch (val) {
        case "first":
          this.getPackageDetails();
          break;
        case "second":
          this.getPackageLogOperates();
          break;
        case "third":
          this.myGetPackageRejectionDetail();
          break;
        case "fourth":
          this.myGetPackageInspectionDetail();
          break;
        case "five":
          this.myGetPackageExceptionDetail();
          break;
        case "six":
          this.myGetPackageFinanceInDetail();
          break;
        case "seven":
          this.getPackageItems();
          break;
      }
    },

    //   -------- 编辑会员 -----------
    changeVip() {
      //  选择会员名称
      this.getVipList();
      this.isDT = true;
    },

    getVipList() {
      //  获取会员列表
      this.vf.pageStart = this.pageStart_u;
      this.vf.pageTotal = this.pageTotal_u;
      Api.getMemberList(this.vf).then((res) => {
        if (res.data.status === "success") {
          this.ut = res.data.result.data;
          this.pageCount_u = res.data.result.pageCount || 0;
        }
      });
    },

    xz(data) {
      //  会员表格选择事件
      if (this.isDT_addC) {
        this.customerForm.userId = data.id;
        this.customerForm.userName = data.memberId;
      } else {
        this.queryData.memberId = data.memberId;
        this.uids = data.id;
      }
      this.isDT = false;
    },
    cxEdit() {
      //  查询按钮
      this.pageStart_u = 1;
      this.getVipList();
    },
    psc_u(val) {
      //  分页事件
      this.pageTotal_u = val;
      this.getVipList();
    },
    pcc_u(val) {
      //  分页事件
      this.pageStart_u = val;
      this.getVipList();
    },
    handleSizeChange(val) {
      this.pageTotal_log = val;
      this.getPackageLogOperates();
    },
    handleCurrentChange(val) {
      this.pageTotal_log = val;
      this.getPackageLogOperates();
    },
    handleClose(num) {
      //  会员弹出框关闭前回调事件
      this.resetData("vf");
    },
    resetData(formName) {
      this.$refs[formName].resetFields();
    },

    //客户追踪
    NewQuestion() {
      this.$router.push("/exceptionhandling/NewQuestion?pageType=" + "track");
    },

    queryTrack() {
      //  轨迹查询按钮
      this.eidtQuery.courierNumber = this.packageDetail.courierNumber || "";
      // this.queryForKD100()
      this.isDT_query = true;
    },

    getLyArr() {
      //  获取来源单据
      Api.financeDocumentstatusChooseList().then((res) => {
        if (res.data.status === "success") {
          this.lyArr = res.data.result;
        } else {
          //   console.log(`${res.data.result}--来源单据`);
        }
      });
    },
    getServeArr() {
      //  获取服务项
      Api.itemServiceListAll({ serviceStatus: "shou:direction_status" }).then(
        (res) => {
          if (res.data.status === "success") {
            this.serveArr = res.data.result;
          } else {
            //   console.log(`${res.data.message}--服务项`);
          }
        }
      );
    },

    getAgentArr() {
      //  获取渠道代理
      Api.channelAgentChooseList().then((res) => {
        if (res.data.status === "success") {
          this.agentArr = res.data.result;
        } else {
          //   console.log(`${res.data.result}--渠道代理`);
        }
      });
    },
    kehujiashou(num) {
      //  客户加收
      if (num === 1) {
        this.isCustomer = true;
        this.titleH = "新建客户加收";
      } else {
        this.getAgentArr();
        this.titleH = "新建代理加收";
        this.isCustomer = false;
      }
      this.getLyArr();
      this.getServeArr();
      this.isDT_addC = true;
    },

    changeServe(val) {
      //  选择服务项的自动填写事件
      let index = this.serveArr.findIndex((v) => v.itemServiceId === val);
      this.customerForm.settlementAmount = this.serveArr[index].unitPrice;
      this.customerForm.serviceName = this.serveArr[index].title;
    },

    addCustomerM() {
      //  客户加收提交
      let index = this.lyArr.findIndex(
        (v) =>
          v.financeDocumentstatusId ===
          this.customerForm.financeDocumentstatusId
      );
      this.customerForm.financeDocumentstatusName =
        this.lyArr[index].documentStatusName;
      if (this.isCustomer) {
        console.log(this.customerForm);
        console.log("this.customerForm");
        delete this.customerForm.userName;
        this.customerForm.sign = tools.getSign(this.customerForm);
        let param1 = {
          financeDocumentstatusId: this.customerForm.financeDocumentstatusId,
          financeDocumentstatusName:
            this.customerForm.financeDocumentstatusName,
          settlementAmount: this.customerForm.settlementAmount,
          userId: this.customerForm.userId,
          comment: this.customerForm.comment,
          documentNumber: this.customerForm.documentNumber,
          itemServiceId: this.customerForm.itemServiceId,
          serviceName: this.customerForm.serviceName,
          storageId: this.customerForm.storageId,
        };
        let sign = tools.getSign(param1);
        param1.sign = sign;
        Api.addCustomerAdditional(param1).then((res) => {
          if (res.data.status === "success") {
            this.$message.success(res.data.message);
            this.isDT_addC = false;
          } else {
            this.$message.error(res.data.message);
          }
        });
      } else {
        let param2 = {
          financeDocumentstatusId: this.customerForm.financeDocumentstatusId,
          financeDocumentstatusName:
            this.customerForm.financeDocumentstatusName,
          settlementAmount: this.customerForm.settlementAmount,
          customerAgentId: this.customerForm.customerAgentId,
          comment: this.customerForm.comment,
          documentNumber: this.customerForm.documentNumber,
          itemServiceId: this.customerForm.itemServiceId,
          serviceName: this.customerForm.serviceName,
          storageId: this.customerForm.storageId,
          customerAgentNumber: this.customerForm.customerAgentNumber,
        };
        let sign = tools.getSign(param2);
        param2.sign = sign;
        Api.addAgentAdditional(param2).then((res) => {
          if (res.data.status === "success") {
            this.$message.success(res.data.message);
            this.isDT_addC = false;
          } else {
            this.$message.error(res.data.message);
          }
        });
      }
    },

    // 获取订单号列表
    courierNumberList() {
      let param = {
        pageStart: 1,
        pageTotal: "",
      };
      Api.courierNumberList(param).then((res) => {
        this.expressList = res.data.result.data || [];
      });
    },
    // 包裹详情
    packageDetails() {
      let param = {
        packageId: this.packageId,
      };
      Api.packageDetails(param).then((res) => {
        this.packDetails = res.data.result || "";
      });
    },
    //客服跟踪 (跳转新建问题)
    kehugenzong() {
      // this.$router.push({
      //   path: "/exceptionhandling/NewQuestion",
      // });
      if (this.packageDetail.courierNumber) {
        this.documentNumbers = this.packageDetail.courierNumber;
      }
      this.documentStatus = "package_v1:document_status"; //包裹单据
      this.showDia1 = true;
    },

    //代理加收 (跳转代理加收)
    dailijiashou() {
      this.$router.push({
        path: "/PayableManagement/AgencyApplication",
      });
    },

    //        ----------   计算表格高度    -------------

    resetHeight() {
      //  重置table高度
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        this.tableHeigth_log = 0;
        resolve();
      });
    },

    fetTableHeight() {
      // 设置table高度
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        this.tableHeigth_log = this.getHeight() - 100;
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },

    getHeight() {
      // 获取表格高度
      let windonHeight = document.body.clientHeight;
      let queryHeigth;
      let btnListHeight = this.$refs.btnList.offsetHeight + 16; // 按钮列表  16==上下的margin
      if (this.judge) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
      } else {
        queryHeigth = 50;
      }
      let pagsHeigth = 60; //分页组件
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 30;
      return Math.floor(windonHeight - otherHeight) || "auto"; //
    },
  },
};
</script>

<style scoped lang="scss">
.contentInner {
  width: 100%;
  display: flex;
  .rightInner {
    width: 200px;
    margin-right: 16px;
    cursor: pointer;
  }
  .leftInner {
    width: 80%;
    /deep/ .el-tabs__item {
      height: 36px;
      line-height: 36px;
      background-color: #f4f4f4;
    }
    /deep/ .el-tabs__content {
      padding: 16px;
    }
    /deep/ .el-descriptions {
      margin-bottom: 10px;
    }
  }
}

/*
    查询轨迹-查询表单
    */
.queryBox {
  margin-top: 20px;
}

.showBox {
  margin-left: 20px;
  .countryGrounp {
    display: flex;
    .countryItem {
      min-width: 100px;
      background-color: #cfcfcf;
      padding: 10px;
    }
    .number {
      min-width: 100px;
      background-color: #ababab;
      padding: 10px;
      font-weight: bold;
      font-size: 16px;
      color: #fff;
    }
  }
}

.contentBox {
  width: 80%;
  margin-top: 20px;
}

.customerForm {
  .queryItem1 {
    margin: 0 auto;
    width: 60%;
    margin-bottom: 10px;
  }
}

.lookBigImgs {
  color: #f0a261;
  cursor: pointer;
}

.lookImage {
  width: 200px;
  height: 200px;
  margin: 10px;
}
</style>
